body {
    background-color: #000;
}
button {
    margin-left: 5px;
}

canvas {
    border: solid 1px white;
    border-radius: 5px;
}