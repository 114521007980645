.menuContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: fixed;
    top: 0;
    right: -500px;
    height: 100vh;
    transition: linear 0.5s;
}

.menuContainer.open {
    right: 0;
}

.button {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.button > button {
    border: none;
    background: none;
    background-color: #282828;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: white;
    font-size: xx-large;
    cursor: pointer;
}

.button.open > button > .arrow {
    transform: rotateZ(-0.5turn);
    transition: 0.5s;
}

.button.open > button > .arrow {
    color: white;
    font-size: xx-large;
    transform: rotateZ(0.5turn);
    transition: 0.5s;
}


.menu {
    margin-top: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 500px;
    background-color: #282828;
    
}

.gen {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 5px;
    margin-top: 2em;
    border: solid darkred 3px;
    border-radius: 5px;
    padding: 5px;
}

.reGen {
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: -2em;
}
.reGen > button {
    flex: 1;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    font-size: xx-large;
    background-color: darkred;
    background-image: linear-gradient(to right, darkred, rgba(255, 255, 255, 0), darkred);
    transition: 1s ease-in;
}
.reGen > button:hover {
    background-color: blue;
}

.details {
    display: flex;
    flex: 4;
    align-items: center;
}
.details > label {
    display: flex;
    flex: 1;
    font-size: larger;
    color: white;
    justify-content: center;
}
.details > div {
    display: flex;
    flex: 1;
    background-color: #0004;
    border-radius: 100px;
    justify-content: center;
}
.details > div div {
    background: none;
    border: none;
    background-color: #0004;
    color: white;
    font-size: large;
    vertical-align: middle;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}
.details > div  div:first-child {
    border-radius: 100px 0px 0px 100px;
}
.details > div  div:last-child {
    border-radius: 0px 100px 100px 0px;
}
.details > div > input[type=number] {
    width: 100%;
    background: none;
    border: none;
    text-align: center;
    font-size: larger;
    color: white;

    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
.details > input[type=range] {
    display: flex;
    flex: 2;
    margin: 10px;
}

.area {
    display: flex;
    flex: 4;
    align-items: center;
    flex-direction: column;
}
div[class^='area-'] {
    display: flex;
    flex:1;
    align-items: center;
}
div[class^='area-'] > label {
    display: flex;
    flex: 1;
    font-size: larger;
    color: white;
    width: 25%;
    justify-content: center;
}
div[class^='area-'] > div {
    display: flex;
    flex: 1;
    background-color: #0004;
    border-radius: 100px;
    justify-content: center;
}
div[class^='area-'] > div div {
    background: none;
    border: none;
    background-color: #0004;
    color: white;
    font-size: large;
    vertical-align: middle;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}
div[class^='area-'] > div  div:first-child {
    border-radius: 100px 0px 0px 100px;
}
div[class^='area-'] > div  div:last-child {
    border-radius: 0px 100px 100px 0px;
}
div[class^='area-'] > div > input[type=number] {
    width: 100%;
    background: none;
    border: none;
    text-align: center;
    font-size: larger;
    color: white;

    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
div[class^='area-'] > input[type=range] {
    display: flex;
    flex: 2;
    margin: 10px;
}


.display {
    display: flex;
    flex: 3;
    margin: 5px;
    padding: 5px;
    border-radius: 0px;
    flex-direction: column;
}

.up {
    display: flex;
    flex: 1;
    margin: 15px 5px 20px 5px;
}

.options {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-around;
    border: solid 2px #0004;
    border-radius: 5px;
    color: white;
    margin-left: 5px;
}
.options > span {
    position: relative;
    margin-left: 10px;
    top: -0.6em;
    font-size: x-large;
    background-color: #282828;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 3.5em;
}

.edge {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    background-color: #0004;
    border-radius: 100px;
    padding: 5px;
    margin: 5px;
    flex: 1;
}

.colorGroup {
    display: flex;
    flex: 1;
    color: white;
    border: solid 2px #0004;
    border-radius: 5px;
    padding: 10px;
    flex-direction: column;
    margin-right: 5px;
}

.colorGroup > span {
    position: relative;
    top: -1em;
    font-size: x-large;
    background-color: #282828;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    max-width: min-content;
}
.color {
    display: flex;
    flex: 1;
    color: white;
}
.color > label {
    flex: 10;
}
.color > span {
    flex: 1;
    border:none;
    background: none;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    margin-left: 50px;
}
.color > span > input[type=color] {
    opacity: 0;
    cursor: pointer;
}

.h, .v {
    display: flex;
    flex: 1;
    align-items: center;
}

.h > span, .v > span {
    display: inline-block;
    border: none;
    background: none;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    flex: 1;
}

.h > span > input[type=color], .v > span > input[type=color]{
    opacity: 0;
    cursor: pointer;
}

.h > label, .v > label {
    flex: 10;
}

select {
    border: none;
    background: none;
    color: white;
}

.intensity {
    color: white;
    border: solid 2px #0004;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.intensity > span {
    position: relative;
    margin-left: 10px;
    top: -0.9em;
    font-size: x-large;
    background-color: #282828;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.intensity >div > label {
    font-size: large;
}